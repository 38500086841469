import React from "react";
import MainLayout from "@components/Layout";
import getQueryValue from "@helpers/getQueryValue";
import ConfirmationAttendance from "@components/ConfirmationAttendance";
// import { styWrapper } from "@components/GenerateLink/styles";
import { styWrapper } from "@components/GenerateLink/styles";
function EGreetingcard({ location }) {
  const nameEticket = getQueryValue(location, "to") || "";
  const code = getQueryValue(location, "code") || "";
  return (
    <MainLayout>
      <div className="homepage">
        <div css={styWrapper}>
          {<ConfirmationAttendance name={nameEticket} code={code} />}
        </div>
      </div>
    </MainLayout>
  );
}

export default EGreetingcard;
