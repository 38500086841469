import React, { useState, Fragment } from "react";
// import QRCode from "react-qr-code";
// import { object } from "prop-types";
import { Link } from "gatsby";
import greetingServices from "../../services/greeting.services";
// import getQueryValue from "@helpers/getQueryValue";
import { styWrapper } from "./styles";
import { navigate } from "gatsby";
import Icon1 from "../../assets/images/avatars/Asset1.webp";
import Icon2 from "../../assets/images/avatars/Asset2.webp";
import Icon3 from "../../assets/images/avatars/Asset3.webp";
import Icon4 from "../../assets/images/avatars/Asset4.webp";
import Icon5 from "../../assets/images/avatars/Asset5.webp";
import Icon6 from "../../assets/images/avatars/Asset6.webp";

function ConfirmationArrendance({ name, code }) {
  const [avatar_id, setId] = useState("1");
  const [authors, setAuthors] = useState("");
  const [greeting, setGreeting] = useState("");
  const [present, setPresent] = useState("true");
  // const [code, setCode] = useState("");
  const [message, setMessage] = useState({ error: false, msg: "" });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (name === "" || greeting === "") {
      setMessage({ error: true, msg: "All Fields are mandatory!" });
      return;
    }
    if (authors === "") {
      setAuthors(name);
    }
    const newGreeting = {
      authors: authors === "" ? name : authors,
      greeting,
      present,
      avatar_id,
      code,
    };
    try {
      await greetingServices.addGreetings(newGreeting);
      setMessage({ error: false, message: "New Greetigns" });
      if (code === "") {
        navigate(`?to=${name}`);
      } else {
        navigate(`?to=${name}&code=${code}`);
      }
    } catch (err) {
      setMessage({ error: true, message: "Data ERROR" });
    }
    setAuthors("");
    setGreeting("");
    setPresent("");
    // setCode("");
  };

  return (
    <div css={styWrapper}>
      <div class="container">
        <h2 className="title">Leave Your Sweetest Message</h2>
        <h3 className="title__sub">Janu & Indra</h3>
        <div className="title__sub">
          {avatar_id === `1` && (
            <img
              src={Icon1}
              style={{ width: 125, height: 125 }}
              alt="avatars"
            />
          )}
          {avatar_id === `2` && (
            <img
              src={Icon2}
              style={{ width: 125, height: 125 }}
              alt="avatars"
            />
          )}
          {avatar_id === `3` && (
            <img
              src={Icon3}
              style={{ width: 125, height: 125 }}
              alt="avatars"
            />
          )}
          {avatar_id === `4` && (
            <img
              src={Icon4}
              style={{ width: 125, height: 125 }}
              alt="avatars"
            />
          )}
          {avatar_id === `5` && (
            <img
              src={Icon5}
              style={{ width: 125, height: 125 }}
              alt="avatars"
            />
          )}
          {avatar_id === `6` && (
            <img
              src={Icon6}
              style={{ width: 125, height: 125 }}
              alt="avatars"
            />
          )}
        </div>

        <Fragment>
          <div class="form-group">
            <label for="exampleInputEmail1">Avatar</label>
            <select
              class="form-control"
              onChange={(e) => setId(e.target.value)}
            >
              <option class="fa" value={1}>
                Avatar 1
              </option>
              <option class="fa" value={2}>
                Avatar 2
              </option>
              <option class="fa" value={3}>
                Avatar 3
              </option>
              <option class="fa" value={4}>
                Avatar 4
              </option>
              <option class="fa" value={5}>
                Avatar 5
              </option>
              <option class="fa" value={6}>
                Avatar 6
              </option>
            </select>
            <label for="exampleInputEmail1">Nama</label>
            <input
              type="text"
              class="form-control"
              placeholder={name}
              // value={name}
              onChange={(e) => setAuthors(e.target.value)}
            ></input>
            <label for="exampleInputEmail1">Ucapan</label>
            <input
              type="text"
              class="form-control"
              placeholder="Message"
              style={{ height: 125 }}
              onChange={(e) => setGreeting(e.target.value)}
            ></input>

            <label for="exampleInputEmail1">Konfirmasi Kehadiran</label>
            <select
              class="form-control"
              onChange={(e) => setPresent(e.target.value)}
              // value={this.state.value}
            >
              <option class="fa" value={true}>
                Hadir
              </option>
              <option class="fa" value={false}>
                Tidak Hadir
              </option>
            </select>
          </div>

          <button
            // onClick={() => handleSubmit()}
            type="submit"
            class="btn btn-primary"
            onClick={handleSubmit}
            // onClick={() => setShowResult(true)}
          >
            Kirim Ucapan
          </button>
        </Fragment>
      </div>
      {code === "" ? (
        <Link to={`?to=${name}`}>
          <button className="btn primary">{`< Kembali ke Homepage`}</button>
        </Link>
      ) : (
        <Link to={`?to=${name}&code=${code}`}>
          <button className="btn primary">{`< Kembali ke Homepage`}</button>
        </Link>
      )}
    </div>
  );
}

// TicketData.propTypes = {
//   guest: object,
//   configData: object,
// };

// TicketData.defaultProps = {
//   guest: {},
//   configData: {},
// };

export default ConfirmationArrendance;
